* {
  scroll-behavior: smooth;
}
::selection {
  color: rgb(101, 28, 101);
  background: rgb(255, 255, 164);
}
footer {
  background-color: rgb(0, 0, 0);
  color: white;
  height: auto;
  /* border-top: 5px dashed white; */
  font-size: 18px;
  font-weight: 100;
  width: 100vw;
}

h2 {
  font-size: 30px;
  font-weight: 200;
}
.footer-main {
  padding-top: 15vh;
}
.sm-links img {
  width: 20px;
  height: auto;
  transition: 0.2s ease-in-out;
}
.sm-links img:hover {
  scale: 1.2;
}
.row-3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.office,
.contact,
.legals {
  width: 29vw;
  padding: 4vh 0 0 0;
}
.mail-to,
.office a,
.phone-no a,
.sm-links a,
.terms a,
.kiazend a,
.email-link {
  text-decoration: none;
  border-bottom: 2px dashed white;
  font-weight: 100;
  color: white;
}
.mail-to:hover,
.office a:hover,
.phone-no a:hover,
.sm-links a:hover,
.terms a:hover,
.kiazend a:hover,
.email-link:hover {
  font-weight: 100;
  color: rgb(110, 110, 110);
  /* border-bottom: 2px solid white; */
}
.footer-last {
  padding: 30px;
  font-weight: lighter;
}
